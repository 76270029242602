import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { getTimeAgoFormat } from '@wix/communities-blog-client-common';
import withTranslate from '../../hoc/with-translate';

const TimeAgo = ({ className, time, timeFormatted, t }) => {
  const timeAgo = timeFormatted || t(getTimeAgoFormat(time), { time });
  return (
    <span title={timeAgo} className={classNames(className, 'time-ago')} data-hook="time-ago">
      {timeAgo}
    </span>
  );
};

TimeAgo.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func,
  time: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  timeFormatted: PropTypes.string,
};

export default withTranslate(TimeAgo);
