import { get } from 'lodash';
import { SANTA_MEMBERS_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';

export const getCommunitiesContext = (state) => state.communitiesContext;

export const getApplicationConfigFromContext = (communitiesContext, appDefinitionId) =>
  get(communitiesContext, appDefinitionId, {});

export const getApplicationConfig = (state, appDefinitionId) =>
  getApplicationConfigFromContext(getCommunitiesContext(state), appDefinitionId);

export const getIsMemberAreaInstalled = (state) =>
  Boolean(getApplicationConfig(state, SANTA_MEMBERS_APP_ID).isInstalled);
