import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../runtime-context';
import SiteLink from './site-link';
import NoopLink from './noop-link';
import forMembersArea from '../../hoc/for-members-area';
import forUserPrivacyType from '../../hoc/for-user-privacy-type';
import { getProfileUrlByUser } from '../../store/profile-urls/profile-urls-selectors';

class ProfileLink extends Component {
  handleClick = (e) => {
    const { navigateToProfile, user } = this.props;
    e.preventDefault();
    navigateToProfile(user.siteMemberId, user.slug);
  };

  render() {
    const { user, navigateToProfile, profileUrl, ...props } = this.props;
    return <SiteLink onClick={this.handleClick} data-hook="profile-link" href={profileUrl} {...props} />;
  }
}

ProfileLink.propTypes = {
  user: PropTypes.object.isRequired,
  memberInfoLink: PropTypes.string,
  profileUrl: PropTypes.string,
};

const mapRuntimeToProps = (state, { user }, actions) => ({
  navigateToProfile: actions.navigateToProfile,
  profileUrl: getProfileUrlByUser(state, user),
});

export default forMembersArea(connect(mapRuntimeToProps)(forUserPrivacyType(ProfileLink)), NoopLink);
