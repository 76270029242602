import { get } from 'lodash';

export const getIsLoading = (state) => state.isLoading;

export const getIsEntityLoading = (state, entity, id = '') =>
  Boolean(id ? get(getIsLoading(state), [entity, id]) : getIsLoading(state)[entity]);

export const getIsCategoryLoading = (state, id) => getIsEntityLoading(state, 'category', id);
export const getIsTagLoading = (state, id) => getIsEntityLoading(state, 'tag', id);
export const getIsPostLoading = (state, slug) => getIsEntityLoading(state, 'post', slug);
export const getIsFeedPostsLoading = (state) => getIsEntityLoading(state, 'feed-posts');
export const getIsRecentPostsLoading = (state, slug) => getIsEntityLoading(state, 'recent-posts', slug);
export const getIsSearchLoading = (state) => getIsEntityLoading(state, 'search');
export const getIsSavingPostDraft = (state, draftKey) => getIsEntityLoading(state, 'postDraft', draftKey);
export const getIsArchiveLoading = (state) => getIsEntityLoading(state, 'archive');
export const getIsHashtagPostsLoading = (state) => getIsEntityLoading(state, 'hashtag-posts');
export const getIsPublishing = (state) => getIsEntityLoading(state, 'publish');
export const getIsRevertingToDraft = (state) => getIsEntityLoading(state, 'revertToDraft');
