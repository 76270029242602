import { flowRight, get } from 'lodash';
import { connect } from '../components/runtime-context';
import {
  getIsViewCountEnabled,
  getIsCommentCountEnabled,
  getIsLikeCountEnabled,
  getIsAuthorNameEnabled,
  getIsPostUpdatedDateEnabled,
  getIsCommentsEnabled,
  getIsRecentPostsEnabled,
  getIsCategoryLabelsEnabled,
  getIsPostTitleEnabled,
  getIsPostPublishDateEnabled,
  getIsReadingTimeEnabled,
  getIsPostDescriptionEnabled,
  getAuthorInfoType,
  getIsAuthorPictureEnabled,
  getIsAuthorBadgeEnabled,
  getIsMoreOptionsMenuEnabled,
  getIsRelatedPostsEnabled,
  getRelatedPostsLabel,
  getIsCategoryLabelEnabled,
} from '../selectors/app-settings-selectors';
import { AUTHOR_INFO_TYPE_NAME, isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_CATEGORY_LABEL } from '@wix/communities-blog-experiments';
import { getIsPostInPreview } from '../store/is-post-in-preview/is-post-in-preview-selectors';
import withHocName from './with-hoc-name';

const mapRuntimeToProps = (state, ownProps) => {
  const isPostInPreview = getIsPostInPreview(state);
  const showComments = !isPostInPreview && getIsCommentsEnabled(state);
  const showRecentPosts = !isPostInPreview && getIsRecentPostsEnabled(state);
  const showRelatedPosts = !isPostInPreview && getIsRelatedPostsEnabled(state);
  const relatedPostsLabelKey = !isPostInPreview && getRelatedPostsLabel(state);
  const showViewCount = !isPostInPreview && getIsViewCountEnabled(state);
  const showCommentCount = showComments && getIsCommentCountEnabled(state);
  const showLikeCount = !isPostInPreview && getIsLikeCountEnabled(state);
  const showAuthorName = getIsAuthorNameEnabled(state);
  const authorInfoType = getAuthorInfoType(state);
  const showPostUpdatedDate = getIsPostUpdatedDateEnabled(state);
  const showCategoryLabels = getIsCategoryLabelsEnabled(state);
  const showPostTitle = getIsPostTitleEnabled(state);
  const showPublishDate = getIsPostPublishDateEnabled(state);
  const showReadingTime = getIsReadingTimeEnabled(state);
  const showPostDescription = getIsPostDescriptionEnabled(state, ownProps.section);
  const oldShowAuthorPictureValue = authorInfoType !== AUTHOR_INFO_TYPE_NAME;
  const showAuthorPicture = getIsAuthorPictureEnabled(state, oldShowAuthorPictureValue);
  const showAuthorBadge = !ownProps.hideAuthorBadge && getIsAuthorBadgeEnabled(state);
  const showMoreOptionsMenu = getIsMoreOptionsMenuEnabled(state);
  const showCategoryLabel =
    isExperimentEnabled(state, EXPERIMENT_CATEGORY_LABEL) &&
    get(ownProps, 'post.categoryIds.length') > 0 &&
    getIsCategoryLabelEnabled(state);

  return {
    showComments,
    showRecentPosts,
    showViewCount,
    showCommentCount,
    showLikeCount,
    showAuthorName,
    showAuthorPicture,
    showAuthorBadge,
    authorInfoType,
    showPostUpdatedDate,
    showCategoryLabels,
    showPostTitle,
    showPublishDate,
    showReadingTime,
    showPostDescription,
    showMoreOptionsMenu,
    showCategoryLabel,
    isMetadataFooterVisible: !isPostInPreview && (showViewCount || showCommentCount || showLikeCount),
    isMetadataHeaderVisible: showAuthorName || showPublishDate || showReadingTime,
    showRelatedPosts,
    relatedPostsLabelKey,
  };
};

export default flowRight(connect(mapRuntimeToProps), withHocName('WithFeedMetadataSettings'));
