import { flowRight, noop } from 'lodash';
import React from 'react';
import { connect } from '../components/runtime-context';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import withHocName from './with-hoc-name';
import { getSection } from '../selectors/section-selectors';
import { getRoute } from '../router/router-selectors';
import { SECTIONS, SECTION_HOMEPAGE, SECTION_POST_LIST } from '@wix/communities-blog-client-common';
import { createFeedClassNameGenerator } from '../services/layout-config';
import { isInRouteUsingFeedPage } from '../services/detect-route';
import { getIsCreatedWithResponsiveEditor, getIsFeedDesignPropsMigrated } from '../selectors/app-settings-selectors';

const withIsFeedDesignEnabled = (WrappedComponent) => {
  const WithIsFeedDesignEnabled = ({
    isCreatedWithResponsiveEditor,
    isFeedDesignPropsMigrated,
    isInFeed,
    isInSearch,
    section,
    ...props
  }) => {
    const applyFeedDesign =
      section === SECTION_POST_LIST ||
      ((isFeedDesignPropsMigrated || isCreatedWithResponsiveEditor) && (isInFeed || isInSearch));
    const getPostClassName = applyFeedDesign ? createFeedClassNameGenerator(section) : noop;

    return (
      <WrappedComponent
        getPostClassName={getPostClassName}
        applyFeedDesign={applyFeedDesign}
        section={section}
        {...props}
      />
    );
  };

  WithIsFeedDesignEnabled.propTypes = {
    isFeedDesignPropsMigrated: PropTypes.bool.isRequired,
    section: PropTypes.oneOf(SECTIONS),
    isInFeed: PropTypes.bool.isRequired,
    isInSearch: PropTypes.bool.isRequired,
    isCreatedWithResponsiveEditor: PropTypes.bool.isRequired,
  };

  WithIsFeedDesignEnabled.defaultProps = { section: SECTION_HOMEPAGE };

  hoistNonReactStatics(WithIsFeedDesignEnabled, WrappedComponent);

  const mapRuntimeToProps = (state, { section }) => {
    const route = getRoute(state);
    return {
      section: getSection(state) || section,
      isInFeed: isInRouteUsingFeedPage(route),
      isInSearch: false, // TODO: isInSearchPage(route),
      isCreatedWithResponsiveEditor: getIsCreatedWithResponsiveEditor(state),
      isFeedDesignPropsMigrated: getIsFeedDesignPropsMigrated(state),
    };
  };

  return flowRight(connect(mapRuntimeToProps), withHocName('WithIsFeedDesignEnabled'))(WithIsFeedDesignEnabled);
};

export default withIsFeedDesignEnabled;
