import { map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const List = ({ containerClassName, itemClassName, separatorClassName = itemClassName, children, separator }) => {
  const content = map(React.Children.toArray(children), (child, index, children) => {
    const result = [
      <li key={child.key} className={itemClassName}>
        {child}
      </li>,
    ];
    if (index < children.length - 1 && separator) {
      result.push(
        <li key={`${child.key}-separator`} className={separatorClassName}>
          {separator}
        </li>,
      );
    }
    return result;
  });

  return <ul className={containerClassName}>{content}</ul>;
};

List.propTypes = {
  children: PropTypes.node,
  separator: PropTypes.element,
  containerClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  separatorClassName: PropTypes.string,
};

List.defaultProps = {
  separator: null,
};

export default List;
