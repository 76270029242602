import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../runtime-context';

import ViewCount from '../view-count';
import ViewCountCompact from '../view-count-compact';
import {
  getViewCount,
  getCommentCount,
  getViewCountFormatted,
  getCommentCountFormatted,
} from '../../store/post-counters/post-counters-selectors';
import styles from './post-stats.scss';
import CommentCountCompact from '../../../comments/components/comment-count-compact';
import CommentCount from '../../../comments/components/comment-count';
import PostLink from '../link/post-link';

const PostStats = ({
  post,
  displayIcons,
  className,
  showViewCount,
  showCommentCount,
  commentClassName,
  viewCount,
  totalComments,
  viewCountFormatted,
  totalCommentsFormatted,
}) => {
  const Views = displayIcons ? ViewCountCompact : ViewCount;
  const Count = displayIcons ? CommentCountCompact : CommentCount;
  const showCommentStats = showCommentCount && (totalComments > 0 || !post.isCommentsDisabled);
  return (
    <div className={classNames(styles.container, 'post-stats', className)} data-hook="post-stats">
      {showViewCount && (
        <Views count={viewCount} countFormatted={viewCountFormatted} className={className} tabIndex={0} />
      )}
      {showCommentStats && (
        <PostLink
          postLink={post.link}
          className={classNames(styles.comments, commentClassName)}
          addHoverClasses={false}
        >
          <Count showZero count={totalComments} countFormatted={totalCommentsFormatted} className={className} />
        </PostLink>
      )}
    </div>
  );
};

PostStats.propTypes = {
  post: PropTypes.object.isRequired,
  displayIcons: PropTypes.bool,
  className: PropTypes.string,
  commentClassName: PropTypes.string,
  showViewCount: PropTypes.bool,
  showCommentCount: PropTypes.bool,
  viewCount: PropTypes.number.isRequired,
  totalComments: PropTypes.number.isRequired,
  viewCountFormatted: PropTypes.string,
  totalCommentsFormatted: PropTypes.string,
};

PostStats.defaultProps = {
  showViewCount: true,
  showCommentCount: true,
};

const mapRuntimeToProps = (state, { post }) => {
  return {
    viewCount: getViewCount(state, post._id),
    totalComments: getCommentCount(state, post._id),
    viewCountFormatted: getViewCountFormatted(state, post._id),
    totalCommentsFormatted: getCommentCountFormatted(state, post._id),
  };
};

export default connect(mapRuntimeToProps)(PostStats);
