import React, { Component } from 'react';
import _ from 'lodash';
import { translate } from 'react-i18next';
import hoistNonReactStatics from 'hoist-non-react-statics';
import createHocName from '../services/create-hoc-name';

const NAME = 'WithTranslate';

export default function withTranslate(WrappedComponent) {
  class WithTranslate extends Component {
    static displayName = createHocName(NAME, WrappedComponent);

    render() {
      const props = _.omit(this.props, ['i18nLoadedAt', 'i18n']);
      return <WrappedComponent {...props} />;
    }
  }

  hoistNonReactStatics(WithTranslate, WrappedComponent);

  return translate(['translation'])(WithTranslate);
}
