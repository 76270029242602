// Constant values are taken from:
// @wix/communities-blog-client-common/src/styles/variables.scss
// @wix/communities-blog-client-common/src/styles/breakpoints.scss
// Don't forget to update them there also
export const POST_PAGE_CONTAINER_PADDING = 60;

export const POST_PAGE_PADDING = 20;
export const POST_PAGE_MIN_WIDTH = 686;
export const POST_PAGE_MAX_WIDTH = 940;

export const POST_CONTENT_AREA_MIN_WIDTH = 586;
export const POST_CONTENT_AREA_MAX_WIDTH = 740;

export const BREAKPOINT_POST_CONTENT_AREA_MIN = 746;
export const BREAKPOINT_POST_CONTENT_AREA_SMALL = 900;

export const DEFAULT_POST_TITLE_FONT_SIZE_MOBILE = 26;
export const DEFAULT_POST_PAGE_FONT_SIZE_MOBILE = 16;
export const DEFAULT_POST_HEADER_TWO_FONT_SIZE_MOBILE = 24;
export const DEFAULT_POST_HEADER_THREE_SIZE_MOBILE = 20;
export const DEFAULT_POST_QUOTES_SIZE_MOBILE = 20;
