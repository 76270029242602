import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './loader.scss';

const Loader = ({ bubbleClassName }) => (
  <div className={styles.container}>
    <span className={classNames(styles.dot, bubbleClassName)} />
    <span className={classNames(styles.dot, bubbleClassName)} />
    <span className={classNames(styles.dot, bubbleClassName)} />
  </div>
);

Loader.propTypes = {
  bubbleClassName: PropTypes.string,
};

Loader.defaultProps = {
  bubbleClassName: 'blog-text-background-color',
};

export default Loader;
