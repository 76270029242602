import PropTypes from 'prop-types';
import React from 'react';
import EmptyStates from '../../components/empty-states';
import withTranslate from '../../hoc/with-translate';

const NoPostsFeed = ({ t, ...props }) => (
  <EmptyStates title={t('no-posts-feed.on-the-way')} content={t('no-posts-feed.stay-tuned')} {...props} />
);

NoPostsFeed.propTypes = {
  t: PropTypes.func,
};

export default withTranslate(NoPostsFeed);
