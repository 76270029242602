import { omit, trimStart } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { SECTION_BLOG_POST_PAGE, isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_PROD_OOI_EDITOR } from '@wix/communities-blog-experiments';
import { connect } from '../runtime-context';
import { isSite } from '../../store/basic-params/basic-params-selectors';
import Wix from '../../services/wix-sdk-polyfill';

const OMIT_PROPS = ['dispatch', 'titleFontClassName', 'tReady', 'lng', 'contentFontClassNameWithStyle'];

const handleClick = (postLink, isSite, navigateInPreview, isExperimentProdOOIEditorEnabled) => (event) => {
  if (!isSite) {
    event.preventDefault();
    if (isExperimentProdOOIEditorEnabled) {
      Wix.Utils.navigateToSection(
        {
          appDefinitionId: BLOG_APP_ID,
          sectionId: SECTION_BLOG_POST_PAGE,
          shouldRefreshIframe: false,
        },
        trimStart(postLink, '/'),
      );
    } else {
      navigateInPreview(postLink);
    }
  }
};

const PostLink = ({
  postLink,
  addHoverClasses,
  children,
  className,
  isSite,
  navigateInPreview,
  isExperimentProdOOIEditorEnabled,
  ...props
}) => {
  const linkClassName = classNames(addHoverClasses && 'blog-link-hover-color', className);
  const linkProps = omit(props, OMIT_PROPS);

  return (
    <a
      href={postLink}
      onClick={handleClick(postLink, isSite, navigateInPreview, isExperimentProdOOIEditorEnabled)}
      className={linkClassName}
      {...linkProps}
    >
      {children}
    </a>
  );
};

PostLink.propTypes = {
  postLink: PropTypes.string,
  addHoverClasses: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  isSite: PropTypes.bool,
  navigateInPreview: PropTypes.func.isRequired,
  isExperimentProdOOIEditorEnabled: PropTypes.bool,
};

PostLink.defaultProps = {
  addHoverClasses: true,
};

const mapRuntimeToProps = (state, _ownProps, actions) => ({
  isSite: isSite(state),
  navigateInPreview: actions.navigateInPreview,
  isExperimentProdOOIEditorEnabled: isExperimentEnabled(state, EXPERIMENT_PROD_OOI_EDITOR),
});

export default connect(mapRuntimeToProps)(PostLink);
