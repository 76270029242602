import React from 'react';
import getDisplayName from 'react-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';

export const withProvisioning = (ProvisioningComponent) => (WrappedComponent) => {
  class WithProvisioning extends React.Component {
    static displayName = `withProvisioning(${getDisplayName(WrappedComponent)})`;

    render() {
      const { provisioningInProgress } = this.props;
      return provisioningInProgress ? <ProvisioningComponent {...this.props} /> : <WrappedComponent {...this.props} />;
    }
  }

  hoistNonReactStatics(WithProvisioning, WrappedComponent);

  return WithProvisioning;
};
