import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ScrollListener from '../scroll-listener';
import { connect } from '../runtime-context';
import scrollParent from '../../services/scroll-parent';
import { isPreview, isSite } from '../../store/basic-params/basic-params-selectors';
import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_PROD_OOI_EDITOR } from '@wix/communities-blog-experiments';

class LoadMore extends Component {
  state = {
    isLoading: false,
  };

  shouldLoadMoreInEditor = (scroll) => {
    const position = scroll.scrollTop + scroll.documentHeight + this.props.threshold;
    const end = scrollParent().scrollHeight + scroll.y;
    return position >= end && this.props.hasMore && !this.props.isLoading;
  };

  shouldLoadMore = () => {
    if (typeof window === 'undefined') {
      return false;
    }

    const { componentId, hasMore, isLoading, threshold } = this.props;
    const component = window.document.getElementById(componentId);
    if (component === null) {
      return false;
    }

    const { height, top } = component.getBoundingClientRect();
    if (hasMore && !isLoading) {
      return window.innerHeight + threshold > height + top;
    }
    return false;
  };

  handleScroll = (scroll) => {
    const { isSite, isPreview, isExperimentProdOOIEditorEnabled } = this.props;
    if (
      isSite || (!isExperimentProdOOIEditorEnabled && isPreview)
        ? this.shouldLoadMore()
        : this.shouldLoadMoreInEditor(scroll)
    ) {
      this.loadMore();
    }
  };

  loadMore = () => {
    const { isLoading, loadMore } = this.props;

    if (isLoading || this.state.isLoading) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    Promise.resolve(loadMore(this.page)).then(() => {
      this.setState({
        isLoading: false,
      });
    });
    this.page++;
  };

  componentDidMount = () => {
    this.page = this.props.pageStart;
  };

  render = () => {
    const { element: Element, children, loader, hasMore, isLoading, ...props } = this.props;
    const passProps = omit(props, [
      'scroll',
      'loadMore',
      'isLoading',
      'pageStart',
      'threshold',
      'dispatch',
      'componentId',
      'isSite',
    ]);

    return (
      <Element {...passProps}>
        {children}
        {hasMore && (isLoading || this.state.isLoading) && loader}
        <ScrollListener onScroll={this.handleScroll} />
      </Element>
    );
  };
}

LoadMore.propTypes = {
  element: PropTypes.string,
  children: PropTypes.node,
  loader: PropTypes.node,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  pageStart: PropTypes.number,
  threshold: PropTypes.number,
  componentId: PropTypes.string,
  isSite: PropTypes.bool,
  isPreview: PropTypes.bool,
  isExperimentProdOOIEditorEnabled: PropTypes.bool,
};

LoadMore.defaultProps = {
  element: 'div',
  hasMore: false,
  pageStart: 2,
  threshold: 500,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isSite: isSite(state),
  isPreview: isPreview(state),
  isExperimentProdOOIEditorEnabled: isExperimentEnabled(state, EXPERIMENT_PROD_OOI_EDITOR),
  componentId: host.id,
});

export default connect(mapRuntimeToProps)(LoadMore);
