import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import withTranslate from '../../hoc/with-translate';

const TimeToRead = ({ t, minutes, isShortLabel, ...props }) => {
  if (typeof minutes !== 'number') {
    return null;
  }

  const label = isShortLabel ? 'time-to-read.short-label' : 'time-to-read.label';
  const containerProps = omit(props, 'tReady', 'lng');

  return (
    <span {...containerProps} title={t(label, { minutes })} data-hook="time-to-read">
      {t(label, { minutes })}
    </span>
  );
};

TimeToRead.propTypes = {
  t: PropTypes.func,
  minutes: PropTypes.number,
  isShortLabel: PropTypes.bool,
};

export default withTranslate(TimeToRead);
