import { BLOG_HEADER_TOTAL_RESULTS } from '@wix/communities-blog-client-common';
import { getHeader } from './get-header';

export const buildPaginationRequestParams = (page, pageSize, cursor) => {
  if (page !== undefined) {
    if (cursor && page > 1) {
      return {
        cursor,
        size: pageSize,
      };
    }

    return {
      offset: (page - 1) * pageSize,
      size: pageSize,
    };
  }
  return {};
};

export const getLastPage = (entityCount, pageSize = 1) => {
  return Math.ceil(entityCount / pageSize);
};

export const getTotalResults = (headers) => parseInt(getHeader(headers, BLOG_HEADER_TOTAL_RESULTS), 10);

export const formatTitle = ({ title, page, lastPage, t }) =>
  page < 2 ? title : `${title} | ${t('pagination.current-position-tile', { page, lastPage })}`;
