import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import PostMetadataLayout from '../post-metadata-layout';
import TimeAgo from '../../../common/components/time-ago';
import TimeToRead from '../../../common/components/time-to-read';
import styles from './post-metadata.scss';

const PostMetadata = ({
  className,
  date,
  dateFormatted,
  readTime,
  isShortReadTimeLabel,
  showPublishDate,
  showReadingTime,
}) => (
  <PostMetadataLayout className={classNames(className, styles.container)}>
    {showPublishDate && <TimeAgo className="post-metadata__date" time={date} timeFormatted={dateFormatted} />}
    {showReadingTime && (
      <TimeToRead className="post-metadata__readTime" minutes={readTime} isShortLabel={isShortReadTimeLabel} />
    )}
  </PostMetadataLayout>
);

PostMetadata.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  dateFormatted: PropTypes.string,
  readTime: PropTypes.number,
  isShortReadTimeLabel: PropTypes.bool,
  className: PropTypes.string,
  showPublishDate: PropTypes.bool,
  showReadingTime: PropTypes.bool,
};

export default PostMetadata;
