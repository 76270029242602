import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_OOI_IN_EDITOR, EXPERIMENT_PROD_OOI_EDITOR } from '@wix/communities-blog-experiments';
import { isEditor, isPreview } from '../store/basic-params/basic-params-selectors';

export const getIsInIFrame = (state) => {
  const isInPreviewIFrame = isPreview(state) && isExperimentEnabled(state, EXPERIMENT_PROD_OOI_EDITOR);
  const isInEditorIFrame = isEditor(state) && !isExperimentEnabled(state, EXPERIMENT_OOI_IN_EDITOR);

  return isInPreviewIFrame || isInEditorIFrame;
};
