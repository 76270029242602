import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './empty-states.scss';
import withFontClassName from '../../hoc/with-font-class-name';
import withCardBorderWidth from '../../hoc/with-card-border-width';

class EmptyStates extends React.Component {
  titleRef = React.createRef();

  // componentDidMount() {
  //   // TODO update after out of iframe, timeout is needed for accessibility audio indication on focus to work
  //   if (this.props.focusOnLoad) {
  //     setTimeout(() => {
  //       if (!this.preventTitleFocus) {
  //         this.titleRef.current && this.titleRef.current.focus();
  //       }
  //     }, 2000);
  //   }
  // }

  render() {
    const {
      title,
      content,
      className,
      children,
      titleFontClassName,
      contentFontClassName,
      borderWidth,
      isLayoutTextOnImage,
      titleClassName,
      focusOnLoad,
    } = this.props;

    const containerClassName = classNames(
      styles.container,
      contentFontClassName,
      className,
      isLayoutTextOnImage ? styles.isLayoutTextOnImage : ['blog-card-background-color', 'blog-text-color'],
      'blog-card-border-color',
    );

    const titleContainerClassName = classNames(titleFontClassName, titleClassName);
    const titleRef = focusOnLoad && this.titleRef;

    return (
      <div data-hook="empty-state-container" className={containerClassName} style={{ borderWidth }}>
        <div className={titleContainerClassName} data-hook="empty-states__title">
          <div ref={titleRef} tabIndex="0" className={styles.header}>
            {title}
          </div>
        </div>
        <div className={styles.content}>{content}</div>
        {children && <div className={styles.controls}>{children}</div>}
      </div>
    );
  }
}

EmptyStates.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  borderWidth: PropTypes.number.isRequired,
  isLayoutTextOnImage: PropTypes.bool,
  titleClassName: PropTypes.string,
  focusOnLoad: PropTypes.bool,
};

EmptyStates.defaultProps = {
  isLayoutTextOnImage: false,
};

export default flowRight(withFontClassName, withCardBorderWidth)(EmptyStates);
