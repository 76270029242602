import { get } from 'lodash';

export const PG_EVENTS = {
  ITEM_CLICKED: 'ITEM_CLICKED',
};

export const CLICK_TARGET_OPTIONS = {
  ITEM_MEDIA: 'item-media',
  ITEM_INFO: 'item-info',
  ITEM_CONTAINER: 'item-container',
};

export const isClickTargetItemMedia = (eventData) =>
  get(eventData, ['clickTarget']) === CLICK_TARGET_OPTIONS.ITEM_MEDIA;

export const isPGEventItemClicked = (eventName) => eventName === PG_EVENTS.ITEM_CLICKED;
