import { IS_MOBILE_DISPLAY_SETTINGS_ENABLED_PATH } from '@wix/communities-blog-client-common';
import { getAppSettingsValue } from './app-settings-base-selectors';

export const getIsMobileDisplaySettingsEnabled = (state) =>
  getAppSettingsValue({
    state,
    key: IS_MOBILE_DISPLAY_SETTINGS_ENABLED_PATH,
    fallback: false,
  });

export const getIsMobileSettingEnabled = (state, key) =>
  getAppSettingsValue({
    state,
    key,
    fallback: false,
  });
